import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router'
import { firestore, removeFile, uploadFile } from '../../../firebase/firebase'
import ProductManager from '../../components/ProductManager/ProductManager'
import _, { cloneDeep } from 'lodash'
import { makeStyles } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid'
import { Skeleton } from '@material-ui/lab';

import { useProduct } from '../../../providers/ProductContext'

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center'
    },
}));

const Product = (props) => {

    const classes = useStyles();

    const { products, error } = useProduct();
    const [product, setProduct] = useState({});
    const [productCategories, setProductCategories] = useState([]);
    const [productLoading, setProductLoading] = useState(true);
    const [initialProduct] = useState({
        name: "",
        category: "",
        description: "",
        descriptionShort: "",
        displayUnit: "",
        docId: uuidv4(),
        inStock: true,
        metadata: [],
        photoURL: "",
        popularity: 0,
        qtyMin: 1,
        qtyStep: 1,
        shipOption: ["Pickup", "Delivery"],
        shippingClass: 'standard',
        showPrice: -1,
        hideOnStore: false,
        taxClass: ["GST", "PST"],
        variantTypes: [],
        variants: []
    })

    //Save and Editing product management
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});

    let path = new URLSearchParams(props.location.search).get("docId");

    const updateData = useCallback((e) => {
        setProduct(e)
    },
        [],
    );

    const createVariant = (generatedVariants, variantSet) => {

        let newVariants = [];

        if (saveState.variantTypes.length === product.variantTypes.length)
            newVariants = [...product.variants];

        generatedVariants.forEach(variant => {
            newVariants.push({
                id: uuidv4(),
                inStock: true,
                photoURL: product.photoURL,
                price: 0.00,
                shipOption: ['Pickup', 'Delivery'],
                swatchPhotoURL: '',
                unit: '',
                variantTypes: variant,
            })
        })

        setProduct({ ...product, 'variants': newVariants })
    }

    const uploadPhoto = async (photo, isVariant, variantName) => {

        if(isVariant){
            let path = 'Products/' + product.docId + '/variants/' +product.slug+'_'+variantName;
            //get variant name

            let query = await uploadFile(path, photo);
            return query.url;
        } else {
            let path = 'Products/' + product.docId + '/' + product.slug;
            let query = await uploadFile(path, photo);
            return query.url;
        }
       


       
    }



    const removePhoto = async (photoURL) => {

        let query = await removeFile(photoURL);

        return query.fileDeleted;
    }

    const saveData = async () => {
        setSaving(true);

        //Remove header photo
        if (saveState.photoURL !== product.photoURL)
            await removePhoto(saveState.photoURL);

        //Add new header photo
        product.photoURL = product.photoURL instanceof File
            ? await uploadPhoto(product.photoURL)
            : product.photoURL

        //Look for variant that exist in saveState but not product (removed)
        //Look for variant that exist in product but not saveState (new)
        //Look for variant that exists in both (changed)
        for (const variant of saveState.variants) {
            let productVariant = product.variants.find(productVariant => productVariant.docId === variant.docId);

            if (productVariant === undefined) {
                if (productVariant.photoURL !== variant.photoURL)
                    await removePhoto(variant.photoURL);
                if (productVariant.swatchPhotoURL !== variant.swatchPhotoURL)
                    await removePhoto(variant.swatchPhotoURL);
            }
        }

        for (const variant of product.variants) {

            variant.photoURL = variant.photoURL instanceof File
                ? await uploadPhoto(variant.photoURL, true, variant.id)
                : variant.photoURL

            if (_.isUndefined(variant.swatchPhotoURL))
                variant.swatchPhotoURL = ''

            variant.swatchPhotoURL = variant.swatchPhotoURL instanceof File
                ? await uploadPhoto(variant.swatchPhotoURL, true, variant.id+ '_swatch')
                : variant.swatchPhotoURL
        }

        if (path === "new")
            firestore.collection('products').add(product)
                .then(() => {
                    setSaveState(_.cloneDeep(product));
                    setSaving(false)
                }).catch((e) => {
                    console.log(e)
                    setSaving(false)
                });
        else
            firestore.collection('products').doc(product.docId)
                .update(product)
                .then(() => {
                    setSaveState(_.cloneDeep(product));
                    setSaving(false)
                }).catch((e) => {
                    console.log(e)
                    setSaving(false)
                });
    };

    const cancelSave = () => {
        setProduct(_.cloneDeep(saveState));
    };

    const createCategory = (categories) => {

        let newCategories = {
            categories: categories
        }

        firestore.collection('productCategories').doc("categories")
            .update(newCategories)
            .then(() => {
                setProductCategories(categories)
            }).catch((e) => console.log(e));
    }

    useEffect(() => {


        //console.log(path)

        setProductLoading(true);
        if (path === 'new') {
            setProduct(cloneDeep(initialProduct));
            setSaveState(cloneDeep(initialProduct));
            setProductLoading(false);
        }
        else {
            let foundProduct = products.find(product => product.docId === path);

            setProduct(cloneDeep(foundProduct));
            setSaveState(cloneDeep(foundProduct));
            setProductLoading(false);
        }

    }, [products, path, initialProduct])

    useEffect(() => {
        firestore.collection('productCategories').get()
            .then(querySnapshot => {
                const categoryResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                    }
                })

                setProductCategories(categoryResponse[0].categories);
            })
            .catch(e => {
                console.log(e.message);
            })

    }, []);

    return (
        <div>
            {productLoading || _.isUndefined(product)
                ? 
                <div className={classes.content}>
                    <Skeleton variant='rect' width={'100vw'} height={'100vh'}/>
                </div>
                : 
                <ProductManager
                    product={product} products={products} createVariant={createVariant}
                    productCategories={productCategories} createCategory={createCategory}
                    saveState={saveState} cancelSave={cancelSave} saving={saving} confirmSave={saveData}
                    updateData={updateData} error={error} />}
        </div>
    )
}

export default withRouter(Product);